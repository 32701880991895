import React, { useState, useEffect, useRef } from "react";
import { Chart as ChartJS, registerables } from "chart.js";

import { fetchChart } from "../../redux/actions/productActions";
import AreaChartWithCustomTooltip from "../AreaChartWithCustomTooltip";
import AreaChartWithCustomTooltip2 from "../AreaChartWithCustomTooltip2";
import ReviewOfferChart from "../ReviewOfferChart";
import BsrChart from "../BsrChart";
import { convertEpochTimeToLocalTime } from "../../utils/formatData";

const options = ["Current", "30", "90", "180"];
const reviewChart = [
  { type: "Offers", color: "#a78bfa" },
  { type: "Ratings", color: "#3c82f7" },
  { type: "Reviews Count", color: "#81e3d8" },
];

const chartTypes = [
  { type: "FBA", color: "#f97316" },
  { type: "FBM", color: "#0ea5e9" },
  { type: "Amazon", color: "#ba900b" },
  { type: "Buy Box", color: "#ec4899" },
];

const Chart = ({ data, ranksandprices }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [chartType, setChartType] = useState("FBA");
  const [selectedOption, setSelectedOption] = useState("Current");
  const [labels, setLabels] = useState([]);
  const [imageLoading, setImageLoading] = useState(false);
  const [graphData, setGraphData] = useState([
    ranksandprices?.FBA_prices?.days_30,
    ranksandprices?.FBA_prices?.days_30,
    ranksandprices?.FBA_prices?.days_90,
  ]);

  const hoveredIndexRef = useRef(null);
  const graph1Ref = useRef(null);
  const graph2Ref = useRef(null);
  const randomRef = useRef(null);

  const plugin = {
    id: "corsair2",
    afterInit: (chart) => {
      chart.corsair = { x: 0, y: 0, draw: false };
    },
    afterEvent: (chart, evt) => {
      const {
        chartArea: { top, bottom, left, right },
      } = chart;
      const { x, y } = evt.event;

      if (x < left || x > right || y < top || y > bottom) {
        chart.corsair = { x, y, draw: false };
        chart.draw();
        // onLeave();
        return;
      }

      // const xIndex = chart.scales.x.getValueForPixel(x);
      // console.log('xIndex bsr:', xIndex, xIndex !== undefined);  // Add this line to debug the xIndex value

      // if (xIndex !== undefined) {
      // atHover(xIndex);
      // }

      // hoveredIndexRef.current = xIndex;
      // console.log('hoveredIndex afterevent:', hoveredIndex.current);
      chart.corsair = { x, y, draw: true };
      chart.draw();
    },
    afterDraw: (chart, _, opts) => {
      // console.log('afterdatasetsdraw chart.config.options.isBarChart:', chart.config.options.isBarChart);
      // console.log('afterdatasetsdraw chart.config.options.isAreaChart:', chart.config.options.isAreaChart);
      // console.log('afterdatasetsdraw corsair:', chart.corsair);
      if (chart._isDrawing) {
        // return;
      }

      let instances = ChartJS.instances;

      const {
        ctx,
        chartArea: { top, bottom },
        scales: { x },
      } = chart;
      const air = chart.corsair;
      if (!air) return;
      if (!air.x) return;
      if (air.x < 0) return;
      const xIndex = chart.scales.x?.getValueForPixel(air.x);

      if (!xIndex) return;

      if (xIndex < 0 || xIndex === null || !chart.data.labels || !x) return;
      if (xIndex >= chart.data.labels.length) return;

      const pixelX = x.getPixelForValue(chart.data.labels[xIndex]);

      if (typeof pixelX === "undefined") {
        console.log("pixelX is undefined");
        return;
      }

      ctx.save();
      ctx.beginPath();
      ctx.moveTo(pixelX, bottom);
      ctx.lineTo(pixelX, top);
      ctx.lineWidth = opts.width || 2;
      ctx.setLineDash(opts.dash || []);
      ctx.strokeStyle = opts.color || "red";
      ctx.stroke();
      ctx.restore();

      let areaInstance, barInstance, reviewInstance;

      // Iterate over instances to identify the charts
      for (let key in instances) {
        if (instances[key].config._config.options.isAreaChart) {
          areaInstance = instances[key];
        } else if (instances[key].config._config.options.isBarChart) {
          barInstance = instances[key];
        } else if (instances[key].config._config.options.isReviewChart) {
          reviewInstance = instances[key];
        }
      }

      // Define a function to update an instance if necessary
      function updateInstance(instance, air) {
        if (instance && !instance.isUpdating) {
          instance.isUpdating = true;

          // Only update if the new position is different
          if (instance.corsair?.x !== air.x || instance.corsair?.y !== air.y) {
            instance.corsair = { x: air.x, y: air.y, draw: true };
            instance.tooltip.setActiveElements(
              [{ datasetIndex: 0, index: xIndex }],
              { x: air.x, y: air.y }
            );
            instance.update();
          }

          instance.isUpdating = false;
        }
      }

      // Update instances based on the chart type
      if (chart.config.options.isBarChart) {
        if (areaInstance) {
          updateInstance(areaInstance, air);
        }
        if (reviewInstance) {
          updateInstance(reviewInstance, air);
        }
      } else if (chart.config.options.isAreaChart) {
        if (barInstance) {
          updateInstance(barInstance, air);
        }
        if (reviewInstance) {
          updateInstance(reviewInstance, air);
        }
      } else if (chart.config.options.isReviewChart) {
        if (barInstance) {
          updateInstance(barInstance, air);
        }
        if (areaInstance) {
          updateInstance(areaInstance, air);
        }
      }
    },
  };

  ChartJS.register(plugin);

  const handleHover = (index) => {
    // hoveredIndexRef.current = index;
  };

  const handleLeave = () => {
    // setHoveredIndex(null);
  };

  const handleImageData = (imageData) => {
    const dataUrl = `data:image/png;base64,${imageData}`;
    // setChartImage(dataUrl);
  };

  const getChartData = () => {
    if (selectedOption === "Current" || selectedOption === "30") {
      const fbaData = ranksandprices?.FBA_prices?.days_30?.map((item) => {
        return {
          time: item.time,
          value: item.price || item?.value,
        };
      });
      const fbmData = ranksandprices?.FBM_prices?.days_30?.map((item) => {
        return {
          time: item.time,
          value: item.price || item?.value,
        };
      });

      const amazonData = ranksandprices?.amazon_prices?.days_30?.map((item) => {
        return {
          time: item.time,
          value: item.price || item?.value,
        };
      });

      const bsrData = ranksandprices?.bsr_ranks?.days_30?.map((item) => {
        return {
          time: item.time,
          value: item.price || item?.value,
        };
      });

      const bbData = ranksandprices?.bb_prices?.days_30?.map((item) => {
        return {
          time: item.time,
          value: item.price || item?.value,
        };
      });

      const offersData = ranksandprices?.offers_count?.days_30?.map((item) => {
        return {
          time: item.time,
          value: item.price || item?.value,
        };
      });

      const ratingsData = ranksandprices?.ratings?.days_30?.map((item) => {
        return {
          time: item.time,
          value: item.price || item?.value,
        };
      });

      const reviewsData = ranksandprices?.reviews_count?.days_30?.map(
        (item) => {
          return {
            time: item.time,
            value: item.price || item?.value,
          };
        }
      );

      return [
        fbaData,
        fbmData,
        amazonData,
        bsrData,
        bbData,
        offersData,
        ratingsData,
        reviewsData,
      ];
    } else if (selectedOption === "90") {
      const fbaData = ranksandprices?.FBA_prices?.days_90?.map((item) => {
        return {
          time: item.time,
          value: item.price || item?.value,
        };
      });

      const fbmData = ranksandprices?.FBM_prices?.days_90?.map((item) => {
        return {
          time: item.time,
          value: item.price || item?.value,
        };
      });

      const amazonData = ranksandprices?.amazon_prices?.days_90?.map((item) => {
        return {
          time: item.time,
          value: item.price || item?.value,
        };
      });

      const bsrData = ranksandprices?.bsr_ranks?.days_90?.map((item) => {
        return {
          time: item.time,
          value: item.price || item?.value,
        };
      });

      const bbData = ranksandprices?.bb_prices?.days_90?.map((item) => {
        return {
          time: item.time,
          value: item.price || item?.value,
        };
      });

      const offersData = ranksandprices?.offers_count?.days_90?.map((item) => {
        return {
          time: item.time,
          value: item.price || item?.value,
        };
      });

      const ratingsData = ranksandprices?.ratings?.days_90?.map((item) => {
        return {
          time: item.time,
          value: item.price || item?.value,
        };
      });

      const reviewsData = ranksandprices?.reviews_count?.days_90?.map(
        (item) => {
          return {
            time: item.time,
            value: item.price || item?.value,
          };
        }
      );

      return [
        fbaData,
        fbmData,
        amazonData,
        bsrData,
        bbData,
        offersData,
        ratingsData,
        reviewsData,
      ];
    } else if (selectedOption === "180") {
      const fbaData = ranksandprices?.FBA_prices?.days_180?.map((item) => {
        return {
          time: item.time,
          value: item.price || item?.value,
        };
      });

      const fbmData = ranksandprices?.FBM_prices?.days_180?.map((item) => {
        return {
          time: item.time,
          value: item.price || item?.value,
        };
      });

      const amazonData = ranksandprices?.amazon_prices?.days_180?.map(
        (item) => {
          return {
            time: item.time,
            value: item.price || item?.value,
          };
        }
      );

      const bsrData = ranksandprices?.bsr_ranks?.days_180?.map((item) => {
        return {
          time: item.time,
          value: item.price || item?.value,
        };
      });

      const bbData = ranksandprices?.bb_prices?.days_180?.map((item) => {
        return {
          time: item.time,
          value: item.price || item?.value,
        };
      });

      const offersData = ranksandprices?.offers_count?.days_180?.map((item) => {
        return {
          time: item.time,
          value: item.price || item?.value,
        };
      });

      const ratingsData = ranksandprices?.ratings?.days_180?.map((item) => {
        return {
          time: item.time,
          value: item.price || item?.value,
        };
      });

      const reviewsData = ranksandprices?.reviews_count?.days_180?.map(
        (item) => {
          return {
            time: item.time,
            value: item.price || item?.value,
          };
        }
      );

      return [
        fbaData,
        fbmData,
        amazonData,
        bsrData,
        bbData,
        offersData,
        ratingsData,
        reviewsData,
      ];
    }
  };

  useEffect(() => {
    const getData = async () => {
      const data = getChartData();

      const [
        dataMain,
        dataFBM,
        dataAmazon,
        dataBsr,
        dataBb,
        dataOffers,
        dataRatings,
        dataReviews,
      ] = data || [];

      // Extract labels
      const extractLabels = (data) => data?.map((d) => d.time) || [];
      const labelsMain = extractLabels(dataMain);
      const labelsFBM = extractLabels(dataFBM);
      const labelsAmazon = extractLabels(dataAmazon);
      const labelsBsr = extractLabels(dataBsr);
      const labelsBb = extractLabels(dataBb);
      const labelsOffers = extractLabels(dataOffers);
      const labelsRatings = extractLabels(dataRatings);
      const labelsReviews = extractLabels(dataReviews);

      const mergedLabels = () => {
        let allLabels = [
          ...labelsMain,
          ...labelsFBM,
          ...labelsAmazon,
          ...labelsBb,
          ...labelsOffers,
          ...labelsRatings,
          ...labelsReviews,
        ];
        let uniqueLabels = [
          ...new Set(
            allLabels.map((label) => convertEpochTimeToLocalTime(label))
          ),
        ];
        uniqueLabels.sort((a, b) => new Date(a) - new Date(b));
        return uniqueLabels;
      };

      const labels = mergedLabels();
      setLabels(labels);

      setGraphData(data);
    };
    getData();
  }, [selectedOption, ranksandprices]);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div
        className="flex flex-col w-full border-primary-light bg-primary p-4 mt-4"
        style={{
          border: "0.5px solid #76F99B",
          borderRadius: "24px",
        }}
      >
        <div
          className="flex cursor-pointer items-center mb-2"
          onClick={toggleAccordion}
        >
          <div
            className="mr-1 text-primary-light font-bold"
            style={{
              fontSize: "16px",
            }}
          >
            Charts
          </div>
          <div>{/* {isOpen ? <FaAngleUp /> : <FaAngleDown />} */}</div>
        </div>
        {isOpen && (
          <>
            <div className="flex mt-2" ref={randomRef}>
              {chartTypes.map((option, index) => (
                <>
                  <div key={index} className="flex items-center mb-1">
                    <div
                      className="w-8 h-1"
                      style={{
                        backgroundColor: option.color,
                        borderRadius: "2px",
                      }}
                    ></div>
                    <span
                      className="ml-1 mr-2 text-white"
                      style={{ fontSize: "12px" }}
                    >
                      {option.type}
                    </span>
                  </div>
                </>
              ))}
            </div>
            <div className="bg-secondary rounded-xl">
              {graphData && graphData.length > 1 && (
                <>
                  <AreaChartWithCustomTooltip
                    data={graphData}
                    labels={labels}
                    hoveredIndex={hoveredIndexRef}
                    title="Stock Price"
                    valueProperty="value"
                    graph1Ref={graph1Ref}
                  />
                </>
              )}
            </div>
            <div className="flex mt-4">
              <div className="flex items-center mb-1">
                <div
                  className="w-8 h-1"
                  style={{ backgroundColor: "#4ade80", borderRadius: "2px" }}
                ></div>
                <span
                  className="ml-1 mr-2 text-white"
                  style={{ fontSize: "12px" }}
                >
                  Seller Rank
                </span>
              </div>
            </div>
            <div className="bg-secondary rounded-xl">
              {graphData && graphData.length > 1 && (
                <>
                  <BsrChart
                    data={graphData}
                    labels={labels}
                    title="Stock Price"
                    valueProperty="value"
                    hoveredIndex={hoveredIndexRef}
                    atHover={handleHover}
                    onLeave={handleLeave}
                    graph2Ref={graph2Ref}
                  />
                </>
              )}
            </div>
            <div className="flex mt-4">
              {reviewChart.map((option, index) => (
                <>
                  <div key={index} className="flex items-center mb-1">
                    <div
                      className="w-8 h-1"
                      style={{
                        backgroundColor: option.color,
                        borderRadius: "2px",
                      }}
                    ></div>
                    <span
                      className="ml-1 mr-2 text-white"
                      style={{ fontSize: "12px" }}
                    >
                      {option.type}
                    </span>
                  </div>
                </>
              ))}
            </div>
            <div className="bg-secondary rounded-xl">
              {graphData && graphData.length > 1 && (
                <>
                  <ReviewOfferChart
                    data={graphData}
                    labels={labels}
                    title="Stock Price"
                    valueProperty="value"
                    hoveredIndex={hoveredIndexRef}
                    atHover={handleHover}
                    onLeave={handleLeave}
                  />
                </>
              )}
            </div>

            <div className="flex mt-4">
              {options.map((option, index) => (
                <div
                  key={index}
                  className={`cursor-pointer text-primary-light ${
                    selectedOption === option ? "text-white" : ""
                  } bg-secondary px-2 py-1 rounded-lg mr-1 mb-2`}
                  onClick={() => setSelectedOption(option)}
                  style={{ fontSize: "12px" }}
                >
                  {option}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Chart;
