import React from "react";

const TooltipSimple = ({ text, width, CustomJsx }) => {
  // Function to check if the text contains HTML tags
  const isHTML = (str) => {
    const doc = new DOMParser().parseFromString(str, "text/html");
    return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
  };

  // Function to render the content safely
  const renderContent = () => {
    // Check if text contains HTML tags
    if (CustomJsx) {
      return <CustomJsx />;
    } else if (isHTML(text)) {
      return <div dangerouslySetInnerHTML={{ __html: text }} />;
    } else {
      return <div>{text}</div>;
    }
  };

  return (
    <div
      className="absolute mb-2 left-1/2 transform -translate-x-1/2 px-2 py-1 bg-primary text-white text-xs z-10 hidden group-hover:block"
      style={{
        width:
          width === "wide"
            ? "150px"
            : width === "extra"
            ? "200px"
            : width === "auto"
            ? "auto"
            : "100px",
        border: "0.1px solid #76F99B",
        borderRadius: "10px",
        textAlign: "center",
        fontSize: "11px",
        boxShadow: "4px 4px 32px 0px #000000CC",
        // whiteSpace: 'pre-wrap',
        top: "85%",
      }}
    >
      {/* Render content based on whether it's HTML or plain text */}
      {renderContent()}
    </div>
  );
};

export default TooltipSimple;
