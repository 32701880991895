import React, { useState, useEffect } from "react";
import Toggle from "./Toggle";
import Tooltip from "./Tooltip";

const QuickInfo = ({ data, handleUpdate }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [selectedOption, setSelectedOption] = useState("Current");
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    const propertiesData = [
      {
        checked: data?.show_breakeven,
        property: "show_breakeven",
        title: "Show Breakeven",
        text: "Show Breakeven",
      },
      {
        checked: data?.show_offer_summary,
        property: "show_offer_summary",
        title: "Show Offer Summary",
        text: "Show Offer Summary",
      },
      {
        checked: data?.show_profit,
        property: "show_profit",
        title: "Show Profit",
        text: "Show Profit",
      },
      {
        checked: data?.show_profit_margin,
        property: "show_profit_margin",
        title: "Show Profit Margin",
        text: "Show Profit Margin",
      },
      {
        checked: data?.show_roi,
        property: "show_roi",
        title: "Show ROI",
        text: "Show ROI",
      },
    ];
    setProperties(propertiesData);
  }, [data]);

  const handleChange = (e, idx) => {
    const property = properties[idx].property;
    const updatedData = {
      ...data,
      [property]: e,
    };
    handleUpdate(updatedData);

    const updatedProperties = properties.map((property, index) => {
      if (index === idx) {
        return { ...property, checked: e };
      }
      return property;
    });
    setProperties(updatedProperties);
  };

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div
      className="flex flex-col w-full border-primary-light bg-primary p-4 mt-4"
      style={{
        border: "0.5px solid #76F99B",
        borderRadius: "24px",
      }}
    >
      <div
        className="flex cursor-pointer items-center mb-2"
        onClick={toggleAccordion}
      >
        <div
          className="mr-1 text-primary-light font-bold"
          style={{
            fontSize: "16px",
          }}
        >
          Quick Info
        </div>
        <div>{/* {isOpen ? <FaAngleUp /> : <FaAngleDown />} */}</div>
      </div>
      {isOpen && (
        <>
          {properties.map((property, idx) => {
            return (
              <div
                className="flex text-white justify-between bg-secondary p-4 rounded-lg align-center mb-2"
                style={{ fontSize: "13px" }}
              >
                <div className="w-3/4 flex" style={{ textAlign: "left" }}>
                  {property.title} <Tooltip text={property.text} />
                </div>
                <div className="w-1/4 flex justify-end items-center">
                  <Toggle
                    checked={property.checked}
                    idx={idx}
                    handleChange={handleChange}
                  />
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default QuickInfo;
