import instance from "./AxiosInstance/unAuthenticated";
import authenticatedInstance from "./AxiosInstance/authenticated";
import { toast } from "sonner";

export const fetchProductDetails = async (data) => {
  try {
    const response = await authenticatedInstance.post("/productinfo", data);

    return response?.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const addLookup = async (data) => {
  try {
    const response = await authenticatedInstance.post("/lookup/add", data);

    return response?.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const fetchLookups = async (asin) => {
  try {
    const response = await authenticatedInstance.post("/lookup", { asin });

    return response?.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const searchProducts = async (searchterm) => {
  try {
    const response = await authenticatedInstance.post("/search", {
      searchterm: searchterm,
    });
    console.log("Search results:", response.data);

    return response?.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const updateSpreadsheetId = async (spreadsheetId) => {
  try {
    const response = await authenticatedInstance.post("/user/createcsv", {
      spreadsheetId,
    });

    return response?.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const fetchSalesData = async (asin, bsr, category) => {
  try {
    const response = await authenticatedInstance.post("/getsales", {
      asin,
      bsr,
      category,
    });
    // console.log("Sales data:", response.data);

    return response?.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const fetchChart = async (data) => {
  try {
    const response = await authenticatedInstance.post("/getchart", data, {
      responseType: "arraybuffer",
    });
    // console.log("Product chart:", response.data);

    return response?.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const fetchSettings = async () => {
  try {
    const response = await authenticatedInstance.get("/user/settings");
    // console.log("Settings:", response.data);

    return response?.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const updateSettings = async (data) => {
  try {
    const response = await authenticatedInstance.post("/user/settings", data);

    return response?.data;
  } catch (error) {
    console.error("Error:", error);
  }
};
