import instance from "./AxiosInstance/unAuthenticated";
import authenticatedInstance from "./AxiosInstance/authenticated";
import { toast } from "sonner";
// import jwt from 'jsonwebtoken';

const cleanEmail = (email) => {
  const cleanedEmail = email?.toLowerCase().trim();
  return cleanedEmail;
};

const cleanPassword = (password) => {
  const cleanedPassword = password.trim();
  return cleanedPassword;
};

export const loginUser = async (uid) => {
  try {
    const { data, status } = await instance.post("/user", {
      uid: uid,
    });

    if (status === 200) {
      const accessToken = data.accessToken;
      localStorage.setItem("accessToken", accessToken);
      return data?.user;
    } else {
      toast.error("Error in loginUser Action");
      return false;
    }
  } catch (error) {
    console.log("error in loginUser Action >>>> ", error);
    return false;
  }
};

export const getUserInfo = async () => {
  try {
    // const accessToken = localStorage.getItem("accessToken");
    // const decodedData = jwt.verify(accessToken, process.env.REACT_APP_JWT_SECRET_KEY);

    // console.log("decodedData in getUserInfo Action >>>> ", decodedData);
    const { data, status } = await authenticatedInstance.get("/verifyUser");

    if (status === 200) {
      return data?.user;
    } else {
      toast.error("Error in getUserInfo Action");
      return false;
    }
  } catch (error) {
    console.log("error in getUserInfo Action >>>> ", error);
    throw error;
  }
};

export const loginWithEmail = async (auth, email, password) => {
  try {
    const cleanedEmail = cleanEmail(email);
    const cleanedPassword = cleanPassword(password);

    const { data, status } = await instance.post("/user", {
      email: cleanedEmail,
      password: cleanedPassword,
    });

    if (status === 200) {
      const accessToken = data.accessToken;
      localStorage.setItem("accessToken", accessToken);
      return data?.user;
    } else {
      toast.error("Error in loginWithEmail Action");
      return false;
    }
  } catch (error) {
    console.log("error in loginWithEmail Action >>>> ", error);
    return false;
  }
};
