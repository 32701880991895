import React from "react";

const SkeletonLine = ({ light }) => {
  return (
    <div
      role="status"
      className="animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center flex items-center mt-2"
    >
      <div className="w-full">
        {light ? (
          <div
            className={`h-2.5 bg-gray-200 rounded-full dark:bg-secondary max-w-[360px]`}
          ></div>
        ) : (
          <div
            className={`h-2.5 bg-gray-500 rounded-full dark:bg-primary max-w-[360px]`}
          ></div>
        )}
      </div>
    </div>
  );
};

export default SkeletonLine;
