import React from "react";
import { Chart as ChartJS, registerables } from "chart.js";
import { Line } from "react-chartjs-2";
import { convertEpochTimeToLocalTime } from "../utils/formatData";

ChartJS.register(...registerables);

const ReviewOfferChart = (props) => {
  const { data: chartDataProps, valueProperty = "value", labels } = props;
  // Extract data
  const [
    dataMain,
    dataFBM,
    dataAmazon,
    dataBsr,
    dataBb,
    dataOffers,
    dataRatings,
    dataReviews,
  ] = chartDataProps || [];

  const mapDataToLabels = (data, labels) => {
    const dataMap = new Map();
    data?.forEach((d) => {
      const localDate = convertEpochTimeToLocalTime(d.time);
      dataMap.set(localDate, parseInt(d[valueProperty], 10));
    });

    return labels.map((label) => dataMap.get(label) || null);
  };

  const fillMissingValues = (data) => {
    const filledData = [];
    let lastValue = null;

    data.forEach((value) => {
      if (value !== null) {
        lastValue = value;
      }
      filledData.push(lastValue);
    });

    return filledData;
  };

  const dataOffers_L = mapDataToLabels(dataOffers, labels);
  const dataRatings_L = mapDataToLabels(dataRatings, labels);
  const dataReviews_L = mapDataToLabels(dataReviews, labels);

  const filledOffers = fillMissingValues(dataOffers_L);
  const filledRatings = fillMissingValues(dataRatings_L);
  const filledReviews = fillMissingValues(dataReviews_L);

  const toInt = (value) => parseInt(value) || undefined;

  // const data = dataOffers?.map(d => toInt(d[valueProperty])).filter(Number.isInteger) || [];
  // dataFBM = dataRatings?.map(d => parseFloat(d[valueProperty])) || [];
  // dataBb = dataReviews?.map(d => toInt(d[valueProperty])).filter(Number.isInteger) || [];

  // const min = Math.min(...data, ...dataFBM, ...dataBb);
  // const max = Math.max(...data, ...dataFBM, ...dataBb);

  const valueFormatter = (num) => {
    if (num >= 1_000_000_000) return (num / 1_000_000_000).toFixed(1) + "B";
    if (num >= 1_000_000) return (num / 1_000_000).toFixed(1) + "M";
    if (num >= 1_000) return (num / 1_000).toFixed(1) + "k";
    return num.toString();
  };

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Offers",
        data: filledOffers,
        borderColor: "#a78bfa",
        borderWidth: 1,
        stepped: true,
        pointRadius: 0,
        yAxisID: "y2",
      },
      {
        label: "Ratings",
        data: filledRatings,
        borderColor: "#3c82f7",
        borderWidth: 1,
        stepped: true,
        pointRadius: 0,
        yAxisID: "y2",
      },
      {
        label: "Review Count",
        data: filledReviews,
        borderColor: "#81e3d8",
        borderWidth: 1,
        stepped: true,
        pointRadius: 0,
        yAxisID: "y1",
      },
    ],
  };

  const chartOptions = {
    isReviewChart: true,
    plugins: {
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: false,
        callbacks: {
          label: (context) => {
            const label = context.dataset.label || "";
            const value = context.raw || 0;
            return `${label}: ${valueFormatter(value)}`;
          },
        },
      },
      legend: {
        display: false, // Disable legend to remove labels at the top
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#E5E7EB", // gray-200
        },
        grid: {
          color: "rgba(229, 229, 229, 0.3)",
        },
        border: {
          color: "#E5E7EB", // gray-200
          width: 1,
        },
      },
      y1: {
        position: "left",
        ticks: {
          callback: valueFormatter,
          color: "#E5E7EB", // gray-200
        },
        grid: {
          color: "rgba(229, 229, 229, 0.3)",
        },
        border: {
          color: "#E5E7EB", // gray-200
          width: 1,
        },
      },
      y2: {
        position: "right",
        suggestedMin: 0,
        ticks: {
          callback: (value) => value.toFixed(1),
          color: "#E5E7EB", // gray-200
        },
        grid: {
          drawOnChartArea: false, // prevent grid lines from appearing over the graph
        },
        border: {
          color: "#E5E7EB", // gray-200
          width: 1,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <div
      className="bg-secondary flex flex-col border rounded-lg shadow-sm w-full p-1"
      style={{
        minWidth: "100%",
        minHeight: "150px",
        // minHeight: '200px'
      }}
    >
      <Line
        data={chartData}
        options={chartOptions}
        style={
          {
            // minWidth: '100%'
          }
        }
      />
    </div>
  );
};

export default ReviewOfferChart;
