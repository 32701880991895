const initState = {
  panels: [
    {
      id: "overview",
      checked: true,
      title: "Overview",
      property: "overview",
    },
    {
      id: "ranks_prices",
      checked: true,
      title: "Ranks and Prices",
      property: "ranks_and_prices",
    },
    {
      id: "alert",
      checked: true,
      title: "Alerts",
      property: "alerts",
    },
    {
      id: "profit_calculator",
      checked: true,
      title: "Profit Calculator",
      property: "profit_calculator",
    },
    {
      id: "chart",
      checked: true,
      title: "Charts",
      property: "charts",
    },
    {
      id: "variation",
      checked: true,
      title: "Variations",
      property: "variations",
    },
    {
      id: "keepa_chart",
      checked: true,
      title: "Keepa Chart",
      property: "keepa_chart",
    },
  ],
};

export default (state = initState, action) => {
  switch (action.type) {
    case "SET_PANEL_ORDER":
      return {
        ...state,
        panels: action.payload,
      };

    default:
      return state;
  }
};
