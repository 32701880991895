import React, { useEffect, useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import TooltipSimple from "../SettingComponents/TooltipSimple";
import Rating from "@mui/material/Rating";
import { formatNumber } from "../../utils/formatData";
import { IoMdStar } from "react-icons/io";

const options = {
  Live: "liveOffers",
  "All Offers": "allOffers",
};

const Offers = ({ data }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [selectedOption, setSelectedOption] = useState("Live");
  const [visibleCount, setVisibleCount] = useState(10);
  const [hasMoreOffers, setHasMoreOffers] = useState(true);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const loadMoreOffers = () => {
    setVisibleCount(visibleCount + 10);
  };

  // Check if there are more offers to load
  const checkMoreOffers = () => {
    if (data?.[options[selectedOption]]?.length <= visibleCount) {
      setHasMoreOffers(false);
    } else {
      setHasMoreOffers(true);
    }
  };

  const getRating = (percentage) => {
    // Ensure percentage is between 0 and 100
    if (percentage < 0) percentage = 0;
    if (percentage > 100) percentage = 100;
    const stars = (percentage / 20).toFixed(1);

    return parseFloat(stars);
  };

  useEffect(() => {
    checkMoreOffers();
  }, [visibleCount, selectedOption, data]);

  return (
    <div
      className="flex flex-col w-full border-primary-light bg-primary p-4 mt-4"
      style={{
        border: "0.5px solid #76F99B",
        borderRadius: "24px",
      }}
    >
      <div className="">
        <div
          className="flex cursor-pointer items-center mb-2"
          onClick={toggleAccordion}
        >
          <div
            className="mr-1 text-primary-light font-bold"
            style={{
              fontSize: "16px",
            }}
          >
            Offers
          </div>
          <div>{/* {isOpen ? <FaAngleUp /> : <FaAngleDown />} */}</div>
        </div>
        {isOpen && (
          <>
            <div className="flex mb-2">
              {Object.keys(options).map((option, index) => (
                <div
                  key={index}
                  className={`cursor-pointer text-primary-light ${
                    selectedOption === option ? "text-white" : ""
                  } bg-secondary px-2 py-1 rounded-lg mr-1`}
                  onClick={() => {
                    setVisibleCount(10);
                    setSelectedOption(option);
                  }}
                  style={{ fontSize: "12px" }}
                >
                  {option}
                </div>
              ))}
            </div>
            <div className="relative">
              <table
                className="w-full mt-2"
                style={{ fontSize: "13px", borderCollapse: "collapse" }}
              >
                <thead className="sticky top-0 bg-primary">
                  <tr className="" style={{ fontWeight: "bold" }}>
                    <div className="flex items-center justify-between px-2 py-0.5">
                      <div className="p-1 w-[5%] text-center"></div>
                      <div className="p-1 w-[17%] text-center">Seller</div>
                      <div className="p-1 w-[15%] text-center">Stock</div>
                      <div className="p-1 w-[18%] text-center">Price</div>
                      <div className="p-1 w-[18%] text-center">Profit</div>
                      <div className="p-1 w-[20%] text-center">ROI</div>
                    </div>
                  </tr>
                </thead>
                <tbody>
                  {data?.[options[selectedOption]]
                    ?.slice(0, visibleCount)
                    ?.map((property, index) => (
                      <tr key={index}>
                        <td className="p-0">
                          <div
                            className="text-center text-white bg-secondary p-4 rounded-lg"
                            style={{
                              marginBottom: "4px",
                              marginTop: index === 0 ? "0" : "4px",
                              padding: "10px",
                            }}
                          >
                            <div className="flex items-center justify-between">
                              <div className="p-1 w-[5%] text-center">
                                {index + 1}
                              </div>{" "}
                              {/* Serial Number */}
                              <div className="group relative cursor-pointer w-[17%] text-center">
                                <a
                                  className={`${
                                    property?.isAmazon
                                      ? "bg-[#644513]"
                                      : property?.isFBA
                                      ? "bg-[#592810]"
                                      : "bg-blue-900/40"
                                  } rounded-lg flex-1 px-4 py-1`}
                                  style={{
                                    border:
                                      property?.isFBA || property?.isAmazon
                                        ? "1px solid #d5a109"
                                        : "1px solid #3b82f6",
                                  }}
                                  href={
                                    process.env.REACT_APP_CLIENT_URL +
                                    "/storefront-stalking?q=" +
                                    property?.sellerId
                                  }
                                  target="_blank"
                                >
                                  {property?.isAmazon
                                    ? "AMZ"
                                    : property?.isFBA
                                    ? "FBA"
                                    : "FBM"}
                                </a>
                                <TooltipSimple
                                  width={"auto"}
                                  CustomJsx={() => (
                                    <div className="flex items-center gap-y-1 flex-col py-1">
                                      <p className="text-medium">
                                        {property?.sellerName}
                                      </p>
                                      {!property?.isAmazon && (
                                        <div className="flex gap-x-1 items-center px-2">
                                          <Rating
                                            name="read-only"
                                            value={Number(
                                              getRating(property?.sellerRating)
                                            )}
                                            precision={0.1}
                                            readOnly
                                            style={{
                                              fontSize: "13px",
                                              color: "#76F99B",
                                            }}
                                            emptyIcon={
                                              <IoMdStar className="text-[#76F99B80]" />
                                            }
                                          />
                                          <span>{`(${formatNumber(
                                            property?.sellerRatingCount
                                          )})`}</span>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                />
                              </div>
                              <div className="p-1 w-[15%] text-center">
                                {property?.stock || "-"}
                              </div>
                              <div className="p-1 w-[18%] text-center">
                                ${property?.price}
                              </div>
                              <div className="p-1 w-[18%] text-center">
                                ${property?.profit}
                              </div>
                              <div className="p-1 w-[20%] text-center">
                                {property?.roi}
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>

              {hasMoreOffers && (
                <button
                  onClick={loadMoreOffers}
                  className="mt-4 px-4 py-2 bg-primary-light text-primary rounded-lg"
                  style={{ fontSize: "14px" }}
                >
                  Load More
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Offers;
