import instance from "./AxiosInstance/unAuthenticated";
import authenticatedInstance from "./AxiosInstance/authenticated";
import { toast } from "sonner";

export const fetchCreditsData = async () => {
  try {
    const response = await authenticatedInstance.get("/stripe/getcredits");

    return response?.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const fetchUserDetails = async () => {
  try {
    const response = await authenticatedInstance.get("/verifyUser");

    return response?.data;
  } catch (error) {
    console.error("Error:", error);
  }
};
