import React, { useEffect, useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import SkeletonLine from "../SkeletonLine";
import { formatNumber } from "../../utils/formatData";

const options = ["current", "30 Days", "90 Days", "180 Days", "All"];

const RanksPrices = ({ data, loading, salesData, settingsData }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [selectedOption, setSelectedOption] = useState(
    settingsData?.defaultValues?.ranksprices_timeframe || "current"
  );
  const [estimatedTime, setEstimatedTime] = useState(1);

  const FBA_prices = data?.FBA_prices;
  const FBM_prices = data?.FBM_prices;
  const amazon_prices = {
    ...data?.amazon_prices,
    current: data?.current?.amazon,
  };
  const bb_drops = data?.bb_prices;
  const keepa_drops = {
    last_30_days: data?.keepa_bsr_drops_30,
    last_90_days: data?.keepa_bsr_drops_90,
    last_180_days: data?.keepa_bsr_drops_180,
    all: data?.keepa_bsr_drops_365,
  };

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (selectedOption === "current" || selectedOption === "30 Days") {
      setEstimatedTime(1);
    } else if (selectedOption === "90 Days") {
      setEstimatedTime(3);
    } else if (selectedOption === "180 Days") {
      setEstimatedTime(6);
    } else if (selectedOption === "All") {
      setEstimatedTime(12);
    }
  }, [selectedOption]);

  const keepaMapping = {
    current: "last_30_days",
    "30 Days": "last_30_days",
    "90 Days": "last_90_days",
    "180 Days": "last_180_days",
    // 'All': 'all'
  };

  const formatValue = (value, prefix = "") => {
    return value === "-" || value === "--" ? value : `${prefix}${value}`;
  };

  const properties = [
    {
      title: "🇺🇸 BSR (TOP%)",
      // value: (data?.current?.bsr && data?.current?.bsr !== -1) ? data?.current?.bsr : '-',
      value:
        selectedOption === "current" && data?.current?.bsr !== -1
          ? formatNumber(data?.current?.bsr)
          : selectedOption === "30 Days" &&
            data?.bsr_ranks?.value_30_days !== -1
          ? formatNumber(data?.bsr_ranks?.value_30_days)
          : selectedOption === "90 Days" &&
            data?.bsr_ranks?.value_90_days !== -1
          ? formatNumber(data?.bsr_ranks?.value_90_days)
          : selectedOption === "180 Days" &&
            data?.bsr_ranks?.value_180_days !== -1
          ? formatNumber(data?.bsr_ranks?.value_180_days)
          : selectedOption === "All" && data?.current?.bsr !== -1
          ? formatNumber(data?.current?.bsr)
          : "-",
    },
    {
      title: "Buy Box",
      // value: data?.current?.buyBoxPrice !== -1
      //     ? formatValue(data?.current?.buyBoxPrice / 100, '$')
      //     : '-',
      value:
        selectedOption === "current" && data?.current?.buyBoxPrice !== -1
          ? formatValue(
              parseFloat(data?.current?.buyBoxPrice / 100).toFixed(2),
              "$"
            )
          : selectedOption === "30 Days" && data?.bb_prices?.data_30?.avg !== -1
          ? formatValue(
              parseFloat(data?.bb_prices?.data_30?.avg / 100).toFixed(2),
              "$"
            )
          : selectedOption === "90 Days" && data?.bb_prices?.data_90?.avg !== -1
          ? formatValue(
              parseFloat(data?.bb_prices?.data_90?.avg / 100).toFixed(2),
              "$"
            )
          : selectedOption === "180 Days" &&
            data?.bb_prices?.data_180?.avg !== -1
          ? formatValue(
              parseFloat(data?.bb_prices?.data_180?.avg / 100).toFixed(2),
              "$"
            )
          : selectedOption === "All" && data?.current?.buyBoxPrice !== -1
          ? formatValue(
              parseFloat(data?.current?.buyBoxPrice / 100).toFixed(2),
              "$"
            )
          : "-",
    },
    {
      title: "Amazon",
      // value: data?.current?.amazon !== -1
      //     ? formatValue(data?.current?.amazon / 100, '$')
      //     : '-',
      value:
        selectedOption === "current" && data?.current?.amazon !== -1
          ? formatValue(parseFloat(data?.current?.amazon / 100).toFixed(2), "$")
          : selectedOption === "30 Days" &&
            data?.amazon_prices?.data_30?.avg !== -1
          ? formatValue(
              parseFloat(data?.amazon_prices?.data_30?.avg / 100).toFixed(2),
              "$"
            )
          : selectedOption === "90 Days" &&
            data?.amazon_prices?.data_90?.avg !== -1
          ? formatValue(
              parseFloat(data?.amazon_prices?.data_90?.avg / 100).toFixed(2),
              "$"
            )
          : selectedOption === "180 Days" &&
            data?.amazon_prices?.data_180?.avg !== -1
          ? formatValue(
              parseFloat(data?.amazon_prices?.data_180?.avg / 100).toFixed(2),
              "$"
            )
          : selectedOption === "All" && data?.current?.amazon !== -1
          ? formatValue(parseFloat(data?.current?.amazon / 100).toFixed(2), "$")
          : "-",
    },
    {
      title: "Lowest FBA",
      value:
        FBA_prices &&
        ((selectedOption === "current" && data?.current?.min_fba !== -1) ||
          (selectedOption === "30 Days" && FBA_prices?.min_30 !== -1) ||
          (selectedOption === "90 Days" && FBA_prices?.min_90 !== -1) ||
          (selectedOption === "180 Days" && FBA_prices?.min_180 !== -1) ||
          (selectedOption === "All" && FBA_prices?.min_180 !== -1))
          ? formatValue(
              (selectedOption === "current" && data?.current?.min_fba) ||
                (selectedOption === "30 Days" &&
                  parseFloat(FBA_prices?.min_30 / 100).toFixed(2)) ||
                (selectedOption === "90 Days" &&
                  parseFloat(FBA_prices?.min_90 / 100).toFixed(2)) ||
                (selectedOption === "180 Days" &&
                  parseFloat(FBA_prices?.min_180 / 100).toFixed(2)) ||
                (selectedOption === "All" &&
                  parseFloat(FBA_prices?.min_180 / 100).toFixed(2)),
              "$"
            )
          : "-",
    },
    {
      title: "Lowest FBM",
      value:
        FBM_prices &&
        ((selectedOption === "current" && data?.current?.min_fbm !== -1) ||
          (selectedOption === "30 Days" && FBM_prices?.min_30 !== -1) ||
          (selectedOption === "90 Days" && FBM_prices?.min_90 !== -1) ||
          (selectedOption === "180 Days" && FBM_prices?.min_180 !== -1) ||
          (selectedOption === "All" && FBM_prices?.min_180 !== -1))
          ? formatValue(
              (selectedOption === "current" && data?.current?.min_fbm) ||
                (selectedOption === "30 Days" &&
                  parseFloat(FBM_prices?.min_30 / 100).toFixed(2)) ||
                (selectedOption === "90 Days" &&
                  parseFloat(FBM_prices?.min_90 / 100).toFixed(2)) ||
                (selectedOption === "180 Days" &&
                  parseFloat(FBM_prices?.min_180 / 100).toFixed(2)) ||
                (selectedOption === "All" &&
                  parseFloat(FBM_prices?.min_180 / 100).toFixed(2)),
              "$"
            )
          : "-",
    },
    {
      title: "Keepa BSR Drops",
      value:
        keepa_drops?.[keepaMapping[selectedOption]] !== undefined
          ? keepa_drops[keepaMapping[selectedOption]] +
            (selectedOption === "current" ? " last 30 days" : "")
          : "-",
    },
    {
      title: "NET BB Price Changes",
      value: formatValue(
        (selectedOption === "current" && bb_drops?.bb_drops_30) ||
          (selectedOption === "30 Days" && bb_drops?.bb_drops_30) ||
          (selectedOption === "90 Days" && bb_drops?.bb_drops_90) ||
          (selectedOption === "180 Days" && bb_drops?.bb_drops_180) ||
          (selectedOption === "All" && bb_drops?.bb_drops_180) ||
          "-"
      ),
      // value: bb_drops && (selectedOption === 'Current' && bb_drops?.bb_drops_30 !== -1 ||
      //     selectedOption === '30' && bb_drops?.bb_drops_30 !== -1 ||
      //     selectedOption === '90' && bb_drops?.bb_drops_90 !== -1 ||
      //     selectedOption === '180' && bb_drops?.bb_drops_180 !== -1 ||
      //     selectedOption === 'All' && bb_drops?.bb_drops_180 !== -1)
      //     ? formatValue(
      //         (selectedOption === 'Current' && bb_drops?.bb_drops_30) ||
      //         (selectedOption === '30' && bb_drops?.bb_drops_30) ||
      //         (selectedOption === '90' && bb_drops?.bb_drops_90) ||
      //         (selectedOption === '180' && bb_drops?.bb_drops_180) ||
      //         (selectedOption === 'All' && bb_drops?.bb_drops_180),
      //         '$'
      //     )
      //     : '-',
    },
    {
      title: "Estimated Sales/ Mo",
      value:
        salesData?.sales_estimation?.monthly_sales &&
        salesData?.sales_estimation?.monthly_sales !== -1
          ? formatNumber(salesData?.sales_estimation?.monthly_sales)
          : "-",
      // value: Number(salesData?.sales_estimation?.monthly_sales) !== -1
      //     ? formatNumber(salesData?.sales_estimation?.monthly_sales)
      //     : '-',
    },
  ];

  return (
    <div
      className="flex flex-col w-full border-primary-light bg-primary p-4 mt-4"
      style={{
        border: "0.5px solid #76F99B",
        borderRadius: "24px",
      }}
    >
      <div className="">
        <div
          className="flex cursor-pointer items-center mb-2"
          onClick={toggleAccordion}
        >
          <div
            className="mr-1 text-primary-light font-bold"
            style={{
              fontSize: "16px",
            }}
          >
            Ranks & Prices
          </div>
          <div>{isOpen ? <FaAngleUp /> : <FaAngleDown />}</div>
        </div>
        {isOpen && (
          <>
            <div className="flex">
              {options.map((option, index) => (
                <div
                  key={index}
                  className={`cursor-pointer text-primary-light ${
                    selectedOption === option ? "text-white" : ""
                  } bg-secondary px-2 py-1 rounded-lg mr-1 mb-2`}
                  onClick={() => setSelectedOption(option)}
                  style={{ fontSize: "12px" }}
                >
                  {option}
                </div>
              ))}
            </div>
            <div className="bg-secondary p-4 rounded-xl">
              {properties.map((property, index) => (
                <div
                  key={index}
                  className="flex justify-between"
                  style={{ fontSize: "13px" }}
                >
                  <div>{property.title}</div>
                  <div className="text-primary-light">
                    {loading ? <SkeletonLine /> : property.value}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RanksPrices;
