import React, { useEffect, useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

const Variations = ({ data }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [properties, setProperties] = useState([]);
  const [variationCount, setVariationCount] = useState(10);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (data) {
      const attributes = data[0]?.attributes;
      const properties =
        attributes?.map((attribute) => attribute?.dimension) || [];

      setProperties(properties);
    }
  }, [data]);

  return (
    <div
      className="flex flex-col w-full border-primary-light bg-primary p-4 mt-4"
      style={{
        border: "0.5px solid #76F99B",
        borderRadius: "24px",
      }}
    >
      <div className="">
        <div
          className="flex cursor-pointer items-center mb-2"
          onClick={toggleAccordion}
        >
          <div
            className="mr-1 text-primary-light font-bold"
            style={{
              fontSize: "16px",
            }}
          >
            Variations{" "}
          </div>
          <div>{/* {isOpen ? <FaAngleUp /> : <FaAngleDown />} */}</div>
        </div>
        {isOpen && (
          <div className="bg-primary rounded-lg">
            <table
              className="w-full mt-2"
              style={{ fontSize: "13px", borderCollapse: "collapse" }}
            >
              <thead className="sticky top-0 bg-primary">
                <tr className="" style={{ fontWeight: "bold" }}>
                  <div className="flex">
                    <div className="p-1 flex-2"></div>
                    <div className="p-1 flex-1">Asin</div>
                    {properties.map((property, index) => (
                      <div className="p-1 flex-1">{property}</div>
                    ))}
                  </div>
                </tr>
              </thead>
              <tbody>
                {data?.slice(0, variationCount)?.map((property, index) => (
                  <tr key={index}>
                    <td className="p-0">
                      <div
                        className="text-center text-white bg-secondary p-4 rounded-lg"
                        style={{
                          marginBottom: "2px",
                          marginTop: index === 0 ? "0" : "4px",
                          padding: "4px 8px",
                          fontSize: "13px",
                        }}
                      >
                        <div className="flex items-center justify-around">
                          <div className="pl-0 pr-2 flex-2">{index + 1}</div>{" "}
                          {/* Serial Number */}
                          <div className=" flex-1">{property?.asin || "-"}</div>
                          {property?.attributes?.map((item, index) => (
                            <div key={index} className=" flex-1">
                              {item?.value}
                            </div>
                          ))}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
                {variationCount < data?.length ? (
                  <button
                    onClick={() => setVariationCount((prev) => prev + 5)}
                    className="mt-3 px-3 py-1 bg-primary-light text-primary rounded-lg"
                    style={{ fontSize: "14px" }}
                  >
                    Load More
                  </button>
                ) : null}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Variations;
