import React, { useEffect, useState } from "react";
import GoogleSheetsAPI from "./GoogleSheetsAPI";

const GoogleSheets = ({ data, salesData, spreadsheetId, setSpreadsheetId }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [reqData, setData] = useState(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const reqData = {
      name: data?.basicInfo?.productName,
      brand: data?.basicInfo?.brand,
      category: data?.basicInfo?.category,
      asin: data?.asin,
      BSR: data?.overview?.BSR?.BSR,
      EstimatedSales: salesData?.sales_estimation?.monthly_sales || "--",
      SalePrice: data?.overview?.prices?.sale_price,
      CostPrice: data?.overview?.prices?.cost_price,
      Profit: "$" + Number(data?.overview?.profit) || "--",
      ROI: data?.overview?.roi ?? "--",
      TotalFees:
        "$" + Number(data?.profitCalculator?.total_fees?.total) || "--",
    };

    setData(reqData);
  }, [data, salesData]);

  // Utility function to convert JSON to CSV with proper escaping
  const convertToCSV = (jsonData) => {
    const array = [Object.keys(jsonData[0])].concat(jsonData);

    return array
      .map((row) => {
        return Object.values(row)
          .map((value) => {
            if (typeof value === "string") {
              // Escape double quotes and wrap the value in double quotes
              value = value.replace(/"/g, '""');
              return `"${value}"`;
            }
            return value;
          })
          .join(",");
      })
      .join("\n");
  };

  // Function to download CSV
  const downloadCSV = () => {
    const reqData = {
      name: data?.basicInfo?.productName,
      brand: data?.basicInfo?.brand,
      category: data?.basicInfo?.category,
      manufacturer: data?.basicInfo?.manufacturer,
      description: data?.description,
      asin: data?.asin,
      EAN: data?.eanList ? data?.eanList[0] : "",
      BSR: data?.overview?.BSR?.BSR,
      EstimatedSales: salesData?.sales_estimation?.monthly_sales || "--",
      SalePrice: data?.overview?.prices?.sale_price,
      CostPrice: data?.overview?.prices?.cost_price,
      Profit: "$" + Number(data?.overview?.profit) || "--",
      ROI: data?.overview?.roi ?? "--",
      TotalFees:
        "$" + Number(data?.profitCalculator?.total_fees?.total) || "--",
    };

    // Convert reqData to an array of objects if it is not already
    const jsonData = [reqData];

    const csv = convertToCSV(jsonData);
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");

    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "data.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div
      className="flex flex-col w-full border-primary-light bg-primary p-4 mt-4"
      style={{
        border: "0.5px solid #76F99B",
        borderRadius: "24px",
      }}
    >
      <div className="">
        <div
          className="flex cursor-pointer items-center mb-2"
          onClick={toggleAccordion}
        >
          <div
            className="mr-1 text-primary-light font-bold"
            style={{
              fontSize: "16px",
            }}
          >
            Google Sheets
          </div>
          <div>{/* {isOpen ? <FaAngleUp /> : <FaAngleDown />} */}</div>
        </div>
        {isOpen && (
          <>
            {/* <button className="bg-secondary text-primary-light py-2 px-4 rounded-md mt-2 hover:bg-primary-light hover:text-secondary transition duration-300"
                            onClick={downloadCSV}
                        >
                            Download CSV
                        </button> */}
            <GoogleSheetsAPI
              spreadsheetId={spreadsheetId}
              setSpreadsheetId={setSpreadsheetId}
              data={reqData}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default GoogleSheets;
