import React, { useState, useEffect } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { calculateProfitMargin } from "../../utils/ProfitCalculator";
import { useSelector } from "react-redux";

const ROI = ({ data, salesData }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [roi, setRoi] = useState(
    Number(data?.roi.substring(0, data?.roi.length - 1))
  );
  const [breakevenSalePrice] = useState(Number(data?.breakeven_sale_price));
  const [salesPrice, setSalesPrice] = useState(
    Number(data?.prices?.sale_price)
  );
  const totalFees = useSelector((state) => state.globalStates.total_fees);
  const prices = useSelector((state) => state.globalStates.prices);
  const [costPrice, setCostPrice] = useState(0);
  const [profit, setProfit] = useState(
    calculateProfitMargin(salesPrice, costPrice, totalFees)[0]
  );

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (!isNaN(prices.cost_price)) {
      setCostPrice(Number(prices.cost_price || 0));
      // when cost price changes, reset the ROI to 100
      const calcRoi = 100;
      const calcProfit = parseFloat(
        (calcRoi / 100) * prices.cost_price
      ).toFixed(2);
      const calcSalesPrice = Number(
        (+prices.cost_price + +calcProfit + +totalFees).toFixed(2)
      );
      setRoi(calcRoi);
      setSalesPrice(calcSalesPrice);
      setProfit(calcProfit);
    } else {
      setCostPrice(0);
    }
  }, [prices.cost_price]);

  useEffect(() => {
    const roi = Number(data?.roi.substring(0, data?.roi.length - 1));
    const costPrice = Number(data?.prices?.cost_price);
    const amazonFees = Number(data?.total_fees?.total);
    const salesPrice = Number(data?.prices?.sale_price);

    setRoi(roi);
    setSalesPrice(salesPrice);
    setProfit(calculateProfitMargin(salesPrice, costPrice, amazonFees)[0]);
  }, [data]);

  const changeHandler = (e, type) => {
    // if roi is changed then calculate the profit and sale price
    if (type === "roi") {
      let roiValue = e.target.value;
      roiValue = roiValue.replace("%", "");
      setRoi(roiValue);
      const calcProfit = parseFloat((roiValue / 100) * costPrice);
      const calcSalesPrice = Number(
        (costPrice + calcProfit + totalFees).toFixed(2)
      );
      setProfit(calcProfit.toFixed(2));
      setSalesPrice(calcSalesPrice);
    }
    // if profit is changed then calculate the roi and sale price
    else {
      let profitValue = e.target.value;
      profitValue = profitValue.replace("$", "");
      profitValue = profitValue.replace(/[^0-9.]/g, "");
      if (isNaN(profitValue)) {
        profitValue = 0;
      }
      if (profitValue.length > 2) {
        const dollars = profitValue.slice(0, -2);
        const cents = profitValue.slice(-2);
        profitValue = `${dollars}.${cents}`;
      }
      setProfit(profitValue);
      profitValue = Number(profitValue);
      const calcRoi = parseInt((profitValue / costPrice) * 100);
      setRoi(calcRoi);
      const calcSalesPrice = Number(
        (Number(costPrice) + profitValue + totalFees).toFixed(2)
      );
      setSalesPrice(calcSalesPrice);
    }
  };

  const profitCalculator = (roi) => {
    // calculate profit for a fixed roi and sale price
    return (roi / 100) * Number(costPrice);
  };

  const RoiPercents = [0, 10, 20, 25, 30, 40, 50, 75, 80, 100, 200];
  return (
    <div
      className="flex flex-col w-full border-primary-light bg-primary p-4 mt-4"
      style={{
        border: "0.5px solid #76F99B",
        borderRadius: "24px",
      }}
    >
      <div className="flex justify-between mb-4">
        <div
          className="flex cursor-pointer items-center"
          onClick={toggleAccordion}
        >
          <div
            className="mr-1 text-primary-light font-bold"
            style={{
              fontSize: "16px",
            }}
          >
            R.O.I
          </div>
          <div>{/* {isOpen ? <FaAngleUp /> : <FaAngleDown />} */}</div>
        </div>
      </div>
      {isOpen && (
        <>
          <div className="bg-secondary p-4 rounded-lg">
            <div
              className="flex justify-between text-primary"
              style={{
                fontSize: "13px",
              }}
            >
              <div
                style={{
                  maxWidth: "30%",
                }}
              >
                <div>
                  <input
                    type="text"
                    value={`${roi}%`}
                    onChange={(e) => changeHandler(e, "roi")}
                    // onBlur={resetValues}
                    className="text-center text-white border border-primary-light rounded-xl bg-primary-dark p-1 mb-2 w-1/2"
                  />
                </div>
              </div>
              <div
                style={{
                  maxWidth: "30%",
                }}
              >
                <div>
                  <input
                    type="text"
                    value={`$${profit}`}
                    // onChange={(e) => changeHandler(e, "profit")}
                    // onBlur={resetValues}
                    onChange={(e) => {
                      const rawValue = e.target.value.replace(/[^0-9]/g, "");
                      e.target.value = `$${rawValue}`;
                      changeHandler(e, "profit");
                    }}
                    onKeyDown={(e) => {
                      if (e.key === ".") {
                        e.preventDefault();
                      }
                    }}
                    className="text-center text-white border border-primary-light rounded-xl bg-primary-dark p-1 mb-2 w-1/2"
                  />
                </div>
              </div>
              <div
                style={{
                  maxWidth: "30%",
                }}
              >
                <div>
                  <input
                    type="text"
                    value={`$${salesPrice}`}
                    disabled
                    className="text-center text-primary-light border border-primary-light rounded-xl bg-primary p-1 mb-2 w-1/2"
                  />
                </div>
              </div>
            </div>
            <div
              className="flex justify-between mt-3"
              style={{
                fontSize: "13px",
                fontWeight: "bold",
              }}
            >
              <div className="w-1/4 text-center">ROI</div>
              <div className="w-1/4 text-center">Profit</div>
              <div className="w-1/4 text-center">Sale Price</div>
            </div>
            {RoiPercents.map((roiRow, index) => (
              <div
                key={index}
                className="flex justify-between text-primary-light mt-1"
                style={{ fontSize: "13px" }}
              >
                <div className="w-1/4 text-center">{roiRow}%</div>
                <div className="w-1/4 text-center">
                  {"$" + profitCalculator(roiRow).toFixed(2)}
                </div>
                <div className="w-1/4 text-center">
                  {"$" +
                    (
                      Number(costPrice) +
                      profitCalculator(roiRow) +
                      totalFees
                    ).toFixed(2)}
                </div>
              </div>
            ))}
            <p className="text-sm text-left mt-4 text-primary-light px-2">
              Based on your cost price of ${costPrice.toFixed(2)}
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default ROI;
